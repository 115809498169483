import { template as template_53d422893e5a47df8494476175aae77e } from "@ember/template-compiler";
const SidebarSectionMessage = template_53d422893e5a47df8494476175aae77e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
