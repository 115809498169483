import { template as template_bc57313b7aff4e47b45aff300a365420 } from "@ember/template-compiler";
const FKText = template_bc57313b7aff4e47b45aff300a365420(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
