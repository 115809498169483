import { template as template_3449cd6bb8eb4ed8a044c3043a3bea9c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const SidebarSectionHeader = template_3449cd6bb8eb4ed8a044c3043a3bea9c(`
  {{#if @collapsable}}
    <DButton
      @title="sidebar.toggle_section"
      @action={{@toggleSectionDisplay}}
      @forwardEvent={{true}}
      aria-controls={{@sidebarSectionContentId}}
      aria-expanded={{if @isExpanded "true" "false"}}
      class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
    >
      {{yield}}
    </DButton>
  {{else}}
    <span class="sidebar-section-header">
      {{yield}}
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionHeader;
