import { template as template_a99511c4373e43c4b4052ac1c96b7e57 } from "@ember/template-compiler";
const WelcomeHeader = template_a99511c4373e43c4b4052ac1c96b7e57(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
