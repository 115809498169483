import { template as template_354cc03da2344415a1e8a715e49bba54 } from "@ember/template-compiler";
const FKLabel = template_354cc03da2344415a1e8a715e49bba54(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
